
import {defineComponent} from 'vue-demi';

export default defineComponent({
  name: "AuthMain",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  created() {
    this.$store.dispatch("checkAuthorization");
  },
  methods: {
    async submit() {
      const result = await this.API.auth.onLogin({email: this.email, password: this.password});
      this.$store.dispatch("setLogin", result.data);
    },
  },
});
